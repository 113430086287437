<template>
  <ion-page id="me-page">
    <ion-content :fullscreen="true">
      <div class="pc-box margin-top-0">
        <div class="pc-min-content-pct">
          <div class="" :class="{ 'fixed-top': !$store.state.isPC, 'sm-top': windowHeight < 670 }">
            <div class="statusBar" ref="statusBar"></div>
            <div class="me-bar" :class="{ userHeader: $store.state.form.token }" ref="topMenu">
              <div class="bar-title" v-if="!$store.state.form.token && !$store.state.isPC">{{ $t("me.MyAccount") }}</div>
              <!-- <div v-if="$store.state.form.token" class="usericon main-color">
                <img :src="$store.state.form.userInfo.icon ? $store.state.form.userInfo.icon : defaultIcon" />
              </div>
              <div class="userNameItem" v-if="$store.state.form.token">
                <span class="name Bold">{{ userName }}</span>
                <img class="edit pointer" src="../assets/edit.png" @click="getEditProfile" />
              </div> -->

              <div v-if="$store.state.form.token" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }" class="bar-title">{{ $t("me.Me") }}</div>
            </div>
          </div>
          <img
            :class="{ 'padding-top-117': !$store.state.isPC, 'sm-top': windowHeight < 670 }"
            style="padding-top: 30px; width: 100%"
            src="../assets/login.jpg"
            v-if="!$store.state.form.token && !$store.state.isPC"
          />
          <div class="me-content" :class="{ 'notLogin': !$store.state.form.token }">
            <div v-if="!$store.state.form.token">
              <img style="border-radius: 20px 20px 0 0; width: 100%" v-if="$store.state.isPC" src="../assets/login.jpg" />

              <div :style="$store.state.isPC ? 'padding: 30px 40px 50px' : windowHeight < 670 ? 'padding-top: 12px' : 'padding-top: 24px'">
                <div class="main-color font-16 Medium" style="line-height: 24px" :class="{ 'letter-ios': $store.state.deviceType == 1 }">
                  {{ $t("me.RorL") }}
                </div>
                <!-- <div class="main-color font-16 loginTip">
                  Don’t have an account?
                  <u @click="goRegistration">Sign up</u>
                </div> -->
                <ion-button class="login-btn font-b" :style="windowHeight < 670 ? 'margin-top: 15px' : ''" @click="goRegistration">{{ $t("me.Register") }}</ion-button>
                <ion-button class="login-btn font-b" :style="windowHeight < 670 ? 'margin-top: 15px' : ''" style="margin-top: 20px" @click="goLogin">{{ $t("me.Login") }}</ion-button>
                <div v-if="windowHeight < 670" class="height50"></div>
              </div>
            </div>

            <div :class="{ 'flex-box': $store.state.isPC, 'padding-top-117': !$store.state.isPC }" v-else>
              <div class="center-item" @click="getEditProfile">
                <div class="icon-image">
                  <img style="width: 30px; margin-left: 5px" src="../assets/edit.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.EditProfile") }}</div>
              </div>
              <div class="center-item" @click="getTransHistory" v-if="!$store.state.isPC">
                <div class="icon-image">
                  <img src="../assets/transaction.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.TransactionHistory") }}</div>
              </div>
              <div class="center-item" @click="getCollectorHistory" v-if="!$store.state.isPC">
                <div class="icon-image">
                  <img src="../assets/collectorcard.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.CollectorCardHistory") }}</div>
              </div>
              <div class="center-item" @click="geteCouponHistory" v-if="!$store.state.isPC">
                <div class="icon-image">
                  <img src="../assets/coupons.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.eCouponHistory") }}</div>
              </div>
              <div class="center-item" @click="geteStampHistory" v-if="!$store.state.isPC">
                <div class="icon-image">
                  <img src="../assets/estamp.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.eStampHistory") }}</div>
              </div>
              <div class="center-item" @click="goRewardsHistory" v-if="!$store.state.isPC">
                <div class="icon-image">
                  <img src="../assets/reward.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.RewardsHistory") }}</div>
              </div>
              <div class="center-item" @click="getReferral">
                <div class="icon-image">
                  <img src="../assets/friends.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.ReferaFriend") }}</div>
              </div>
              <div class="center-item" @click="goChangePassword">
                <div class="icon-image">
                  <img src="../assets/icon-password.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.Changepassword") }}</div>
              </div>
              <div class="center-item" :class="{ 'no-border': !$store.state.isPC }" @click="logoutEvent">
                <div class="icon-image">
                  <img src="../assets/logout.png" />
                </div>
                <div class="text-align-l font-18 main-color Bold">{{ $t("me.Logout") }}</div>
              </div>
            </div>
            <!-- <ion-button class="login-btn" @click="getTest">app test</ion-button> -->
          </div>
        </div>
      </div>
      <wallet-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton } from "@ionic/vue";
import walletBtn from "@/components/walletBtn.vue";
import config from "../config";

// import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';

export default {
  name: "mePage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, IonButton, walletBtn },
  data() {
    return {
      windowHeight: window.innerHeight,
      canLogout: true,
    };
  },
  computed: {
    userName() {
      const firstName = this.$store.state.form.userInfo.firstName;
      const lastName = this.$store.state.form.userInfo.lastName;
      if (firstName || lastName) {
        return firstName + " " + lastName;
      } else {
        return "";
      }
    },
  },
  methods: {
    goRegistration() {
      this.$store.state.form.registrationBack = "/main/me";
      this.$store.state.firstPage = 1;
      this.$router.push({
        path: "/signup",
      });
      this.sendGAEvent("Click", "Me", "SignUp");
    },
    goLogin() {
      this.$store.state.form.registrationBack = "/main/me";
      this.$store.state.firstPage = 2;
      this.$router.push({
        path: "/login",
      });
      this.sendGAEvent("Click", "Me", "Log in");
    },
    goRewardsHistory() {
      this.$router.push({
        path: "/rewardsHistory",
      });
      this.sendGAEvent("Click", "Me", "Rewards history");
    },
    getTest() {
      this.$router.push({
        path: "/test",
      });
    },
    getEditProfile() {
      this.$router.push({
        path: "/editProfile",
      });
      this.sendGAEvent("Click", "Me", "Edit Profile");
    },
    goChangePassword() {
      this.$router.push({
        path: "/changePassword",
      });
      this.sendGAEvent("Click", "Me", "Change Password");
    },
    getTransHistory() {
      this.$router.push({
        path: "/TransHistory",
      });
      this.sendGAEvent("Click", "Me", "Trans History");
    },
    geteStampHistory() {
      this.$router.push({
        path: "/eStampHistory",
      });
      this.sendGAEvent("Click", "Me", "eStamp History");
    },
    getCollectorHistory() {
      this.$router.push({
        path: "/collectorHistory",
      });
      this.sendGAEvent("Click", "Me", "Collector History");
    },
    geteCouponHistory() {
      this.$router.push({
        path: "/eCouponHistory",
      });
      this.sendGAEvent("Click", "Me", "eCoupon History");
    },
    getReferral() {
      this.$router.push({
        path: "/referral",
      });
      this.sendGAEvent("Click", "Me", "Referral");
    },
    async logoutEvent() {
      if (!this.canLogout) return;
      this.canLogout = false;
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.logout;
      setTimeout(() => {
        this.canLogout = true;
      }, 1500);
      await this.$store.dispatch("apiEvent", objdata);
      this.$store.state.form.token = "";
      this.$store.state.form.eCouponList = [];
      this.$store.state.form.stampList = [];
      this.$store.state.form.collectCardList = [];
      this.$store.state.userInfo = {};
      this.sendGAEvent("Click", "Me", "Log out");

      this.setStorageEvent();
      this.setGAUserId("");

      if (this.$store.state.appVersion) {
        this.$router.replace({
          path: "/main/landing",
        });
      } else {
        this.$router.replace({
          path: "/register",
        });
      }
    },
    // async FacebookLogin() {
    // const FACEBOOK_PERMISSIONS = ['email', 'user_birthday', 'user_photos', 'user_gender'];
    // const result = await FacebookLogin.login({
    //   permissions: FACEBOOK_PERMISSIONS
    // });
    // console.log(JSON.stringify(result));
    // const token = await FacebookLogin.getCurrentAccessToken();
    // console.log(JSON.stringify(token));
    // },
  },
  ionViewDidEnter() {
    this.clickTabEvent("me");
    this.$store.state.exitApp = false;
    // if (this.$route.query.type) {
    //   this.$router.push({
    //     path: "/main/landing",
    //   });
    // }

    // setTimeout(() => {
    //   let hi1 = $("#me-page").attr("class").search("ion-page-hidden") > -1 || false;

    // }, 100);
    this.sendGAPageView("Me");
  },
  ionViewWillEnter() {
    this.canLogout = true;
    console.log("me", new Date().getTime());
  },
};
</script>
